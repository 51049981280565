import { Guid } from '@komo-tech/core/models/Guid';
import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';

type LiveQuizAnswerKeys = Partial<{
  [key in keyof LiveQuizAnswer]: any;
}>;

export class LiveQuizAnswer {
  public id: Guid;
  public answerText: string;
  public answerImageUrl: string;

  get AnswerImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.answerImageUrl);
  }

  constructor(props: LiveQuizAnswerKeys = {} as any) {
    Object.assign(this, props);
    this.id = Guid.valueOrEmpty(props.id);
  }
}
