import { Guid } from '@komo-tech/core/models/Guid';
import { IHasId } from '@komo-tech/core/models/IHasId';
import { asBoolean } from '@komo-tech/core/utils/boolean';
import { utcDateOrNow } from '@komo-tech/core/utils/date';
import isNil from 'lodash/isNil';

import { CardResultProperties } from './CardResultProperties';

export class CardResult implements IHasId<Guid> {
  id: Guid;
  cardId: Guid;
  name: string;
  updatedAt: Date;
  createdAt: Date;
  prizeId?: Guid;
  prizeSmsId?: Guid;
  prizeEmailId?: Guid;
  recipients: number = 0;
  isDefault: boolean;
  properties: CardResultProperties;

  constructor(props?: Partial<CardResult>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrEmpty(props.id);
    this.cardId = Guid.valueOrEmpty(props.cardId);
    this.createdAt = utcDateOrNow(props.createdAt);
    this.updatedAt = utcDateOrNow(props.updatedAt);
    this.properties = new CardResultProperties(props.properties);
    this.prizeId = Guid.valueOrUndefined(props.prizeId);
    this.prizeEmailId = Guid.valueOrUndefined(props.prizeEmailId);
    this.prizeSmsId = Guid.valueOrUndefined(props.prizeSmsId);
    this.isDefault = asBoolean(props.isDefault);
  }

  getUploadPath(siteId: Guid, cardId: Guid) {
    return `site/${siteId}/cards/${cardId}/results/${this.id}`;
  }

  IsInstantWinAvailable() {
    const instantWin = this.properties.getProbability();
    return this.recipients < instantWin.maxWinners || instantWin.isUnlimited;
  }

  isValid(): {
    isValid: boolean;
    missingImage: boolean;
    missingTrigger: boolean;
  } {
    const missingImage = !this.properties.getImage();
    const questionCorrectFilter = this.properties.getQuestionCorrectFilter();
    const missingTrigger =
      !this.isDefault &&
      (isNil(questionCorrectFilter?.MinCorrectAnswers) ||
        isNil(questionCorrectFilter?.MaxCorrectAnswers));

    return {
      isValid: !missingImage && !missingTrigger,
      missingImage,
      missingTrigger
    };
  }
}
