import { CardTypes } from '@/common/models/CardTypes';
import { Guid } from '@komo-tech/core/models/Guid';
import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';
import { SiteCard } from '@/common/models/SiteCard';
import { SiteCardHelper } from '@/common/models/SiteCardHelper';
import { mapArray } from '@komo-tech/core/utils/array';
import {
  getPrimaryBackgroundColor,
  getPrimaryTextColor
} from '@/common/utils/ButtonFunctions';

import { SitePageProperties } from '@/common/models/pages/shared/SitePageProperties';
import { IPicker } from './IPicker';
import { PickerLayout } from './PickerLayout';
import { PickerProperties } from './PickerProperties';
import { PickerTag } from './PickerTag';

const DefaultBackgroundImage = ImageDataModel.fromUrlAndSize(
  '/assets/images/picker-formation-background.png',
  {
    width: 375,
    height: 329
  }
);

const DefaultSlotImage = ImageDataModel.fromUrlAndSize(
  '/assets/images/picker-slot-placeholder.png',
  {
    height: 40,
    width: 40
  }
);

export class BasePicker implements SiteCard, IPicker {
  static DefaultConfirmText = 'Confirm';
  static DefaultRandomText = 'Randomize';
  static DefaultBackgroundImage = DefaultBackgroundImage;
  static DefaultSlotImage = DefaultSlotImage;

  id: Guid;
  siteId: Guid;
  readonly type: CardTypes = CardTypes.Picker;

  tags: PickerTag[];
  layouts: PickerLayout[];
  properties: PickerProperties;

  get progressEnabled() {
    return this.properties.ProgressEnabled;
  }

  get pickerScreenTitle() {
    return this.properties.PickerScreenTitle;
  }

  get modalHeaderProps() {
    return {
      color: this.properties.ModalHeaderColor,
      backgroundColor: this.properties.ModalHeaderBackgroundColor,
      image: this.properties.ModalHeaderImage
    };
  }

  get modalBackgroundProps() {
    return {
      image: this.properties.ModalBackgroundImage,
      backgroundColor: this.properties.ModalBackgroundColor
    };
  }

  get backgroundImage() {
    return this.properties.BackgroundImage || BasePicker.DefaultBackgroundImage;
  }

  get hasOptionDefaultImage() {
    return !!this.properties.OptionDefaultImageJson;
  }

  //why does this one fall back to slot default image?
  get optionDefaultImage() {
    return this.hasOptionDefaultImage
      ? this.properties.OptionDefaultImage
      : this.slotDefaultImage;
  }

  get optionDefaultImage2() {
    return this.hasOptionDefaultImage
      ? this.properties.OptionDefaultImage
      : undefined;
  }

  get slotDefaultImage() {
    return !!this.properties.SlotDefaultImage
      ? this.properties.SlotDefaultImage
      : BasePicker.DefaultSlotImage;
  }

  get slotDefaultImageAspectRatio() {
    const img = this.slotDefaultImage;
    return img.height / img.width;
  }

  constructor(props: Partial<BasePicker> = {}) {
    Object.assign(this, props);
    SiteCardHelper.applyDefaults(this, props);
    this.tags = mapArray(props.tags, (x) => new PickerTag(x));
    this.layouts = mapArray(props.layouts, (x) => new PickerLayout(x));
    this.properties = new PickerProperties(props.properties);
  }

  getLayout(id: string | Guid): PickerLayout | undefined {
    return this.layouts.find((x) => x.id.equals(id));
  }

  getSlotById(id: Guid) {
    const slots = this.layouts.flatMap((x) => x.slots);
    return slots.find((x) => x.id.equals(id));
  }

  getModalButtonProps(properties: SitePageProperties) {
    return {
      color: getPrimaryTextColor({
        overrideColor: this.properties.ButtonTextColor,
        properties
      }),
      bgColor: getPrimaryBackgroundColor({
        overrideColor: this.properties.ButtonBackgroundColor,
        properties
      })
    };
  }

  getRandomizeButtonProps(properties: SitePageProperties) {
    return {
      color: getPrimaryTextColor({
        overrideColor: this.properties.RandomizeButtonTextColor,
        properties
      }),
      bgColor: getPrimaryBackgroundColor({
        overrideColor: this.properties.RandomizeButtonBackgroundColor,
        properties
      }),
      borderColor: getPrimaryBackgroundColor({
        overrideColor: this.properties.RandomizeButtonBorderColor,
        properties
      })
    };
  }
}
