import { Guid } from '@komo-tech/core/models/Guid';
import { mapArray } from '@komo-tech/core/utils/array';
import { asNumber } from '@komo-tech/core/utils/number';
import { orderByAscending } from '@komo-tech/core/models/IHasOrder';

import { SwiperOptionGroupProperties } from '../shared/SwiperOptionGroupProperties';
import { FrontSwiperOption } from './FrontSwiperOption';

export class FrontSwiperOptionGroup {
  id: Guid;
  swiperId: Guid;
  name: string;
  order: number;
  options: FrontSwiperOption[];
  properties: SwiperOptionGroupProperties;

  constructor(props?: Partial<FrontSwiperOptionGroup>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.swiperId = Guid.valueOrNew(props.swiperId);
    this.order = asNumber(props.order, 1);
    this.options = mapArray(props.options, (x) => new FrontSwiperOption(x));
    this.properties = new SwiperOptionGroupProperties(props.properties);
    this.sanitiseOptions();
  }

  sanitiseOptions() {
    this.options = this.options.sort(orderByAscending);
    this.options.forEach((o, i) => {
      o.order = i + 1;
    });
  }

  getOptionImages() {
    return this.options.map((x) => x.properties.Image).filter((x) => !!x?.url);
  }
}
