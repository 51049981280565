import { CardSubTypes } from '@/common/models/CardSubTypes';
import { CardTypes } from '@/common/models/CardTypes';
import { Guid } from '@komo-tech/core/models/Guid';
import { SiteCard } from '@/common/models/SiteCard';
import { SiteCardHelper } from '@/common/models/SiteCardHelper';

import { VideoProperties } from './VideoProperties';

export class Video implements SiteCard {
  id: Guid;
  siteId: Guid;
  readonly type: CardTypes = CardTypes.Video;
  name: string;
  properties: VideoProperties;
  videoUrl: string;
  views: number;
  showStatsEnabled = false;

  constructor(props: Partial<Video> = {}) {
    Object.assign(this, props);
    SiteCardHelper.applyDefaults(this, props);
    this.properties = new VideoProperties(props.properties);
  }

  resolveLayout(subType: CardSubTypes) {
    const isFacebook =
      this.videoUrl?.indexOf('://fb.watch') !== -1 ||
      this.videoUrl?.indexOf('://www.fb.watch') !== -1;
    if (subType === CardSubTypes.FacebookWatch || isFacebook) {
      return 'inline';
    }

    const isYouTube =
      this.videoUrl?.indexOf('://youtu.be') !== -1 ||
      this.videoUrl?.indexOf('://www.youtube.com') !== -1;

    const isVimeo =
      this.videoUrl?.indexOf('://vimeo.com') !== -1 ||
      this.videoUrl?.indexOf('://www.vimeo.com') !== -1;

    const isTwitch =
      this.videoUrl?.indexOf('://twitch.tv') !== -1 ||
      this.videoUrl?.indexOf('://www.twitch.tv') !== -1;

    if (isYouTube || isVimeo || isTwitch) {
      return 'iframe';
    }

    return subType === CardSubTypes.Video ? 'iframe' : 'inline';
  }
}

export const resolveVideoLayout = (value: string) => {
  const isFacebook =
    value?.indexOf('://fb.watch') !== -1 ||
    value?.indexOf('://www.fb.watch') !== -1;

  if (isFacebook) {
    return 'inline';
  } else {
    return 'iframe';
  }
};

export const youtubeRegex =
  /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&?]*).*/;

export const getYoutubeVideoId = (videoUrl: string) => {
  return youtubeRegex.exec(videoUrl);
};
