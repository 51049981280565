import { Guid } from '@komo-tech/core/models/Guid';

import { StyledBackgroundProps } from '@/common/components/StyledBackground';
import { CardTypes } from '@/common/models/CardTypes';
import { SitePageProperties } from '@/common/models/pages/shared/SitePageProperties';
import { SiteCard } from '@/common/models/SiteCard';

import { SiteCardHelper } from '../../SiteCardHelper';
import { ScratchAndWinProperties } from '../shared/ScratchAndWinProperties';

export class AdminScratchAndWin implements SiteCard {
  id: Guid;
  siteId: Guid;
  readonly type: CardTypes = CardTypes.ScratchAndWin;
  properties: ScratchAndWinProperties;

  constructor(partial?: Partial<AdminScratchAndWin>) {
    partial = partial || {};
    Object.assign(this, partial);
    SiteCardHelper.applyDefaults(this, partial);
    this.properties = new ScratchAndWinProperties(partial?.properties);
  }

  getScratchScreenProps() {
    return this.properties.getScratchScreenProps();
  }

  getCoverProps(properties: SitePageProperties) {
    return {
      image: this.properties.CoverImage,
      title: this.properties.CoverTitle,
      buttonText: this.properties.CoverButtonText,
      buttonBackgroundColor:
        this.properties.getButtonBackgroundColor(properties),
      buttonTextColor: this.properties.getButtonTextColor(properties),
      hideButton: !this.properties.CoverButtonEnabled,
      hideTitle: !this.properties.CoverTitleEnabled
    };
  }

  getModalTitle() {
    return this.properties.getModalTitle();
  }

  getModalBackgroundProps(
    properties: SitePageProperties
  ): StyledBackgroundProps {
    return this.properties.getModalBackgroundProps(properties);
  }
}
