import isNil from 'lodash/isNil';

import { CardTypes } from '@/common/models/CardTypes';
import { Guid } from '@komo-tech/core/models/Guid';
import { HeightWidth } from '@komo-tech/core/models/HeightWidth';
import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';
import { SitePageProperties } from '@/common/models/pages/shared/SitePageProperties';
import { SiteCard } from '@/common/models/SiteCard';
import { SiteCardHelper } from '@/common/models/SiteCardHelper';

import { CanvasProperties } from '../shared/CanvasProperties';
import { getCanvasBackground, getCanvasItems } from '../shared/functions';

export class AdminCanvas implements SiteCard {
  id: Guid;
  siteId: Guid;
  readonly type: CardTypes = CardTypes.Canvas;
  stateVersion: number;
  widthRatio: number;
  heightRatio: number;
  itemsJson: string;
  backgroundJson: string;
  imageUrl: string;
  properties: CanvasProperties;

  get image() {
    return ImageDataModel.fromJsonOrUrl(this.imageUrl);
  }

  constructor(props?: Partial<AdminCanvas>) {
    props = props || {};
    Object.assign(this, props);
    SiteCardHelper.applyDefaults(this, props);
    this.properties = new CanvasProperties(props.properties);

    if (isNil(this.stateVersion)) this.stateVersion = 0;
    if (isNil(this.widthRatio) && isNil(this.heightRatio)) {
      this.widthRatio = 16;
      this.heightRatio = 9;
    }
  }

  getBackground() {
    return getCanvasBackground(this.backgroundJson);
  }

  getState() {
    return {
      items: getCanvasItems(this.itemsJson),
      background: this.getBackground(),
      size: this.getSize()
    };
  }

  getSize() {
    const size: HeightWidth = {
      height: this.heightRatio,
      width: this.widthRatio
    };
    return size;
  }

  getButtonTextColor(properties: SitePageProperties) {
    return this.properties.getButtonTextColor(properties);
  }

  getButtonBackgroundColor(properties: SitePageProperties) {
    return this.properties.getButtonBackgroundColor(properties);
  }

  getSubmitButtonProps(properties: SitePageProperties) {
    return {
      color: this.properties.getButtonTextColor(properties),
      backgroundColor: this.properties.getButtonBackgroundColor(properties)
    };
  }

  getCoverButtonTextProps() {
    return {
      text: this.properties.CoverSubmitText,
      defaultText: this.properties.DefaultCoverSubmitText
    };
  }

  getUploadPath() {
    return `site/${this.siteId}/card/${this.id}/canvas`;
  }

  hasInteractiveTextItems() {
    return getCanvasItems(this.itemsJson).some((x) =>
      x.isInteractiveTextItem()
    );
  }
}
