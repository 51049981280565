import { immerable } from 'immer';

import { Guid } from '@komo-tech/core/models/Guid';
import { mapArray } from '@komo-tech/core/utils/array';
import { utcDateOrNow } from '@komo-tech/core/utils/date';

import { LiveSurveyGameplayProperties } from '../shared/LiveSurveyGameplayProperties';

export class FrontLiveSurveyGameplay {
  [immerable] = true;
  id: Guid;
  cardId: Guid;
  userName: string;
  competitionEntryId?: Guid;
  userSessionId: Guid;

  get hasEntry() {
    return !!this.competitionEntryId;
  }

  questions: FrontLiveSurveyGameplayQuestion[];

  reactions: FrontLiveSurveyGameplayReaction[];

  get totalQuestions() {
    return this.questions?.length || 0;
  }

  properties: LiveSurveyGameplayProperties;
  createdAt: Date;
  updatedAt: Date;

  constructor(props?: Partial<FrontLiveSurveyGameplay>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.cardId = Guid.valueOrNew(props.cardId);
    this.userSessionId = Guid.valueOrNew(props.userSessionId);
    this.competitionEntryId = Guid.valueOrUndefined(props.competitionEntryId);
    this.questions = mapArray(
      props.questions,
      (x) => new FrontLiveSurveyGameplayQuestion(x)
    );
    this.reactions = mapArray(
      props.reactions,
      (x) => new FrontLiveSurveyGameplayReaction(x)
    );
    this.properties = new LiveSurveyGameplayProperties(props?.properties);
    this.createdAt = utcDateOrNow(props.createdAt);
    this.updatedAt = utcDateOrNow(props.updatedAt);
  }

  getLikeReaction(questionId: Guid | string) {
    return this.reactions.find((x) => x.isMatch(questionId, 'like'));
  }

  hasLikedQuestion(questionId: Guid | string) {
    return this.hasReactedToQuestion(questionId, 'like');
  }

  hasReactedToQuestion(questionId: Guid | string, key: string) {
    return this.reactions.some((x) => x.isMatch(questionId, key));
  }
}

export class FrontLiveSurveyGameplayQuestion {
  [immerable] = true;
  id: Guid;
  content: string;
  isAnswered: boolean = false;
  isPinned: boolean = false;
  isApproved: boolean = false;
  createdAt: Date;
  updatedAt: Date;

  constructor(props?: Partial<FrontLiveSurveyGameplayQuestion>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.createdAt = utcDateOrNow(props.createdAt);
    this.updatedAt = utcDateOrNow(props.updatedAt);
  }
}

export class FrontLiveSurveyGameplayReaction {
  [immerable] = true;
  id: Guid;
  reactionKey: string;
  questionId: Guid;

  constructor(props?: Partial<FrontLiveSurveyGameplayReaction>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.questionId = Guid.valueOrNew(props.questionId);
  }

  isMatch(questionId: Guid | string, key: string) {
    return this.reactionKey === key && this.questionId.equals(questionId);
  }
}
