import {
  CarouselData,
  resolveCarouselData
} from '@/common/components/Carousel/types';
import { AssetTypes } from '@/common/models/AssetTypes';
import { Guid } from '@komo-tech/core/models/Guid';

import { CarouselItemProperties } from '../shared';

export class AdminCarouselItem {
  id: Guid;
  carouselId: Guid;
  type: AssetTypes;
  data: string;
  libraryItemId?: Guid;
  order: number;
  properties: CarouselItemProperties;
  constructor(props?: Partial<AdminCarouselItem>) {
    props = props || {};
    Object.assign(this, props);
    this.properties = new CarouselItemProperties(props.properties);
    this.id = Guid.valueOrNew(props.id);
    this.carouselId = Guid.valueOrEmpty(props.carouselId);
    this.libraryItemId = Guid.valueOrUndefined(props.libraryItemId);
  }

  resolveData(): CarouselData {
    return resolveCarouselData(this.type, this.data);
  }
}
