import isArray from 'lodash/isArray';

import { SitePageProperties } from '@/common/models/pages/shared/SitePageProperties';
import { SiteCardProperties } from '@/common/models/SiteCardProperties';
import { asBoolean } from '@komo-tech/core/utils/boolean';

import { CanvasBackground } from './CanvasBackground';

export interface BlackListedWord {
  id: string;
  text: string;
}
export class CanvasProperties implements SiteCardProperties {
  // Some cards override the competition CTA button styling using these properties:
  ButtonTextColor?: string;
  ButtonBackgroundColor?: string;
  BackgroundChoicesEnabled?: boolean;
  CoverTitle?: string;
  UserOptionsTitle?: string;
  UserOptionsDescription?: string;
  BackgroundChoicesJson?: string;
  BlacklistedWordListJson?: string;

  readonly DefaultCoverSubmitText = 'CREATE YOUR OWN';
  CoverSubmitText: string;

  readonly DefaultUserOptionsSubmitText = 'Create';
  UserOptionsSubmitText: string;

  readonly DefaultConfirmSubmitTextWithCompetition = 'Enter and share';
  readonly DefaultConfirmSubmitText = 'Share';
  ConfirmSubmitText: string;

  readonly DefaultUserUploadButtonText = 'Upload your own';
  UserUploadButtonText?: string;
  UserUploadButtonBackground?: string;
  UserUploadButtonTextColor?: string;

  readonly DefaultProcessingHeader = 'Processing your image';
  ProcessingHeader?: string;
  readonly DefaultProcessingSubHeader = "Won't be long!";
  ProcessingSubHeader?: string;

  constructor(props?: Partial<CanvasProperties>) {
    props = props || {};
    Object.assign(this, props);

    this.BackgroundChoicesEnabled = asBoolean(props.BackgroundChoicesEnabled);
    if (!this.CoverSubmitText)
      this.CoverSubmitText = this.DefaultCoverSubmitText;

    if (!this.UserOptionsSubmitText)
      this.UserOptionsSubmitText = this.DefaultUserOptionsSubmitText;

    if (!this.BackgroundChoicesJson) {
      this.BackgroundChoicesJson = '[]';
    }

    if (!this.ConfirmSubmitText)
      this.ConfirmSubmitText = this.DefaultConfirmSubmitText;

    if (!this.UserUploadButtonText)
      this.UserUploadButtonText = this.DefaultUserUploadButtonText;

    if (!this.UserUploadButtonBackground) {
      this.UserUploadButtonBackground = '#0755EB';
    }

    if (!this.UserUploadButtonTextColor)
      this.UserUploadButtonTextColor = '#FFFFFF';

    if (!this.ProcessingHeader)
      this.ProcessingHeader = this.DefaultProcessingHeader;

    if (!this.ProcessingSubHeader)
      this.ProcessingSubHeader = this.DefaultProcessingSubHeader;
  }

  getBackgroundChoices() {
    const fallback: CanvasBackground[] = [];
    try {
      if (!this.BackgroundChoicesJson) return fallback;
      const items = JSON.parse(this.BackgroundChoicesJson);
      return isArray(items)
        ? items.map((x) => new CanvasBackground(x))
        : fallback;
    } catch (error) {
      console.error(error);
      return fallback;
    }
  }

  getButtonTextColor(properties: SitePageProperties) {
    const { AccentTextColor } = properties;
    const { ButtonTextColor } = this;
    return ButtonTextColor || AccentTextColor || '#FFFFFF';
  }

  getButtonBackgroundColor(properties: SitePageProperties) {
    const { AccentBackgroundColor } = properties;
    const { ButtonBackgroundColor } = this;
    return ButtonBackgroundColor || AccentBackgroundColor || '#000000';
  }

  getBlacklistedWords() {
    const fallback: BlackListedWord[] = [];
    try {
      if (!this.BlacklistedWordListJson) return fallback;
      const items = JSON.parse(this.BlacklistedWordListJson);
      return isArray(items) ? (items as BlackListedWord[]) : fallback;
    } catch (error) {
      console.error(error);
      return fallback;
    }
  }
}
