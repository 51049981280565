import { CSSProperties } from 'react';

import { CardLayout } from '@/common/models/CardLayout';

import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';

const getMaxWidthMinWidth = (
  isGrid: boolean,
  isImageGrid: boolean
): CSSProperties => ({
  maxWidth: isImageGrid ? '148px' : isGrid ? '150px' : '100%',
  minWidth: isImageGrid ? '148px' : isGrid ? '150px' : '100%'
});
const hasImage = (layout?: CardLayout, image?: ImageDataModel) =>
  (!layout ||
    [
      CardLayout.GridImage,
      CardLayout.GridImageAndText,
      CardLayout.ListImageAndText
    ].includes(layout)) &&
  !!image?.url;

const hasText = (layout?: CardLayout, text?: string) =>
  (!layout || layout !== CardLayout.GridImage) && !!text;

const isGrid = (layout?: CardLayout) =>
  !!layout &&
  [CardLayout.GridImage, CardLayout.GridImageAndText].includes(layout);

const validateTextOption = (option: { text: string }) => {
  return !!option.text && option.text.length > 0;
};

const validateImageOption = (option: { imageUrl: string }) => {
  return !!option.imageUrl && option.imageUrl.length > 0;
};

const validateImageTextOption = (option: { imageUrl: string; text: string }) =>
  validateTextOption(option) || validateImageOption(option);

const showOption = (
  option: { text: string; imageUrl: string },
  layout?: CardLayout
) =>
  [CardLayout.ListText].includes(layout)
    ? validateTextOption(option)
    : [CardLayout.GridImage].includes(layout)
      ? validateImageOption(option)
      : validateImageTextOption(option);

const OptionsFunctions = {
  getMaxWidthMinWidth,
  hasImage,
  hasText,
  isGrid,
  showOption
};

export default OptionsFunctions;
