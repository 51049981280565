import { CardTypes } from '@/common/models/CardTypes';
import { Guid } from '@komo-tech/core/models/Guid';
import { SitePageProperties } from '@/common/models/pages/shared/SitePageProperties';
import { SiteCard } from '@/common/models/SiteCard';

import { SiteCardHelper } from '../../SiteCardHelper';
import { InstantWinProperties } from '../shared/InstantWinProperties';

export class AdminInstantWin implements SiteCard {
  id: Guid;
  siteId: Guid;
  readonly type: CardTypes = CardTypes.InstantWin;
  properties: InstantWinProperties;

  constructor(partial?: Partial<AdminInstantWin>) {
    partial = partial || {};
    Object.assign(this, partial);
    SiteCardHelper.applyDefaults(this, partial);
    this.properties = new InstantWinProperties(partial?.properties);
  }

  getInstantWinScreen() {
    return this.properties.getInstantWinScreen();
  }

  getCoverProps(properties: SitePageProperties) {
    return {
      image: this.properties.CoverImage,
      title: this.properties.CoverTitle,
      hideTitle: !this.properties.CoverTitleEnabled,
      buttonText: this.properties.CoverButtonText,
      buttonBackgroundColor: this.getButtonBackgroundColor(properties),
      buttonTextColor: this.getButtonTextColor(properties)
    };
  }

  getButtonBackgroundColor(properties: SitePageProperties) {
    return this.properties.getButtonBackgroundColor(properties);
  }

  getButtonTextColor(properties: SitePageProperties) {
    return this.properties.getButtonTextColor(properties);
  }

  getModalTitle() {
    return this.properties.getModalTitle();
  }
}
