import isNil from 'lodash/isNil';

import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';
import { asBoolean } from '@komo-tech/core/utils/boolean';
import { asNumberOrUndefined } from '@komo-tech/core/utils/number';

export class SwiperResultProperties {
  DataCaptureDisabled?: boolean;
  ConfettiEnabled?: boolean;
  PlayAgainDisabled?: boolean;
  ViewAnswersDisabled?: boolean;
  DescriptionLexicalStateJson?: string;

  ImageJson: string;

  get Image() {
    return ImageDataModel.fromJsonOrUrl(this.ImageJson);
  }

  Title?: string;
  Description?: string;

  MinCorrectAnswers?: number;
  MaxCorrectAnswers?: number;

  constructor(props?: Partial<SwiperResultProperties>) {
    props = props || {};
    Object.assign(this, props);

    this.ViewAnswersDisabled = asBoolean(props.ViewAnswersDisabled);
    this.PlayAgainDisabled = asBoolean(props.PlayAgainDisabled);
    this.DataCaptureDisabled = asBoolean(props.DataCaptureDisabled);
    this.ConfettiEnabled = asBoolean(props.ConfettiEnabled);

    this.MinCorrectAnswers = asNumberOrUndefined(props.MinCorrectAnswers);
    this.MaxCorrectAnswers = asNumberOrUndefined(props.MaxCorrectAnswers);
  }

  getTriggerRangeLabel(placeholder = 'Set range') {
    if (isNil(this?.MinCorrectAnswers) || isNil(this?.MaxCorrectAnswers)) {
      return placeholder;
    }

    if (this.MinCorrectAnswers === this.MaxCorrectAnswers) {
      return `${this.MinCorrectAnswers.toLocaleString()} correct`;
    }

    return `${this.MinCorrectAnswers.toLocaleString()} - ${this.MaxCorrectAnswers.toLocaleString()} correct`;
  }
}
