import { CardLayout } from '@/common/models/CardLayout';
import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';
import {
  calculateSliderPoints,
  isCorrectSliderGuess
} from '@/common/models/predictive/PredictiveFunctions';
import { asBoolean } from '@komo-tech/core/utils/boolean';
import {
  asNumber,
  asNumberOrDefault,
  asNumberOrUndefined
} from '@komo-tech/core/utils/number';
import OptionsFunctions from '@/common/utils/OptionsFunctions';

export class PredictiveQuestionProperties {
  InternalName?: string;

  ImageUrl?: string;

  get Image(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.ImageUrl);
  }

  HasImage?: boolean;
  HideText?: boolean;
  QuestionLayout?: CardLayout;

  ButtonText?: string = 'Confirm your guess';
  ButtonTextColor?: string = '#ffffff';
  ButtonBackgroundColor?: string = '#000000';

  SliderImage?: string;

  get PredictiveQuestionSliderImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.SliderImage);
  }

  SliderAnswer?: number;
  SliderAnswerRange?: number;
  SliderMinNumber?: number;
  SliderMaxNumber?: number;
  SliderDefaultNumber?: number;

  IsSurveyQuestion?: boolean;

  //sponsor
  IsSponsored?: boolean;
  SponsorAvatar?: string;

  get SponsorAvatarImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.SponsorAvatar);
  }

  SponsorText?: string;

  //no correct answer
  HasNoCorrectAnswer?: boolean;
  NoCorrectAnswerExplainer?: string;

  // Points
  MaxPoints: number;
  MinPoints: number;
  DynamicPointsEnabled: boolean;

  //superuser
  InitialResponseThreshold: number = 10;

  constructor(props: Partial<PredictiveQuestionProperties> = {}) {
    Object.assign(this, props);
    this.HasImage = asBoolean(props.HasImage);
    this.IsSurveyQuestion = asBoolean(props.IsSurveyQuestion);
    this.IsSponsored = asBoolean(props.IsSponsored);
    this.HideText = asBoolean(props.HideText);
    this.SliderAnswer = asNumberOrUndefined(props.SliderAnswer);
    this.SliderAnswerRange = asNumber(props.SliderAnswerRange);
    this.SliderMinNumber = asNumberOrUndefined(props.SliderMinNumber);
    this.SliderMaxNumber = asNumberOrUndefined(props.SliderMaxNumber);
    this.HasNoCorrectAnswer = asBoolean(props.HasNoCorrectAnswer);
    this.InitialResponseThreshold = asNumber(
      props.InitialResponseThreshold,
      10
    );
    this.MaxPoints = asNumberOrDefault(props.MaxPoints, 10_000);
    this.MinPoints = asNumberOrDefault(props.MinPoints, 1_000);
    this.DynamicPointsEnabled = asBoolean(props.DynamicPointsEnabled, true);
  }
  static createSliderProperties() {
    return new PredictiveQuestionProperties({
      SliderMinNumber: 0,
      SliderMaxNumber: 100,
      SliderAnswerRange: 0
    });
  }

  isCorrectSliderGuess(guess?: number) {
    if (guess === undefined) {
      return false;
    }

    return isCorrectSliderGuess(
      {
        answer: this.SliderAnswer,
        tolerance: this.SliderAnswerRange,
        min: this.SliderMinNumber,
        max: this.SliderMaxNumber
      },
      guess
    );
  }

  isExactSliderGuess(guess?: number) {
    if (guess === undefined) {
      return false;
    }
    return guess == this.SliderAnswer;
  }

  getSliderPoints(guess?: number) {
    if (guess === undefined) {
      return 0;
    }

    return calculateSliderPoints(
      {
        answer: this.SliderAnswer,
        tolerance: this.SliderAnswerRange,
        min: this.SliderMinNumber,
        max: this.SliderMaxNumber,
        maxPoints: this.MaxPoints,
        minPoints: this.MinPoints
      },
      guess
    );
  }

  getLayoutOptions() {
    const layout = this.QuestionLayout;
    const isGrid = OptionsFunctions.isGrid(layout);
    const optionsToShow = isGrid ? 4 : 6;
    return { layout, isGrid, optionsToShow } as const;
  }
}
