import { Guid } from '@komo-tech/core/models/Guid';
import { TriviaActionScreenProperties } from '@/common/models/trivia/shared/TriviaActionScreenProperties';
import { TriviaActionScreenTypes } from '@/common/models/trivia/shared/TriviaActionScreenTypes';
import { asNumber } from '@komo-tech/core/utils/number';
import { getSiteUrl } from '@/common/utils/SiteFunctions';

export class AdminTriviaActionScreen {
  id: Guid;
  type: TriviaActionScreenTypes;
  name: string;
  order: number;
  properties: TriviaActionScreenProperties;
  constructor(props?: Partial<AdminTriviaActionScreen>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.order = asNumber(props.order);
    this.properties = new TriviaActionScreenProperties(props.properties);
  }

  hasHeader() {
    return (
      this.type === TriviaActionScreenTypes.BeatScore ||
      this.type === TriviaActionScreenTypes.Leaderboard
    );
  }

  hasSubHeader() {
    return (
      this.type === TriviaActionScreenTypes.BeatScore ||
      this.type === TriviaActionScreenTypes.SmartestTeam ||
      this.type === TriviaActionScreenTypes.Question ||
      this.type === TriviaActionScreenTypes.HardestQuestion
    );
  }

  hasQuestionId() {
    return this.type === TriviaActionScreenTypes.Question;
  }

  hasExpertScore() {
    return this.type === TriviaActionScreenTypes.BeatScore;
  }

  hasExpertImage() {
    return this.type === TriviaActionScreenTypes.BeatScore;
  }

  hasFieldId() {
    return this.type === TriviaActionScreenTypes.SmartestTeam;
  }

  getUrl(gameId: Guid, siteDomain?: string) {
    return `${getSiteUrl(siteDomain)}/trivia/action-screen/${gameId}/${
      this.id
    }`;
  }
}
