import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { CardDescriptor } from '@/common/models/CardDescriptor';
import { Guid } from '@komo-tech/core/models/Guid';

import { PublicCardActions as Actions } from '../_actions';
import { PublicCardsCacheKeys as CacheKeys } from '../_cacheKeys';

export const useFrontCardDescriptor = (
  cardId: Guid,
  options?: UseQueryOptions<CardDescriptor>
) => {
  const query = useQuery(
    CacheKeys.descriptor(cardId),
    () => Actions.getDescriptorAsync(cardId),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options
    }
  );

  const isLoading = query.isLoading || query.isInitialLoading;
  return [query.data, isLoading] as const;
};
