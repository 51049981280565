import isAfter from 'date-fns/isAfter';

import { Form } from '@/common/models/form/Form';
import { Guid } from '@komo-tech/core/models/Guid';
import { SitePageProperties } from '@/common/models/pages/shared/SitePageProperties';
import { SiteCard } from '@/common/models/SiteCard';
import {
  getPrimaryBackgroundColor,
  getPrimaryTextColor
} from '@/common/utils/ButtonFunctions';
import { utcDateOrNow, utcDateOrUndefined } from '@komo-tech/core/utils/date';
import { asString } from '@komo-tech/core/utils/string';

import { CompetitionEntryStyles } from './CompetitionEntryStyles';
import { CompetitionProperties } from './CompetitionProperties';
import { CompetitionThrottleFrequencies } from './CompetitionThrottleFrequencies';

export class Competition {
  id: Guid;
  name: string;
  entryStyle = CompetitionEntryStyles.One;
  couponOfferId?: Guid;
  openedAt?: Date;
  closedAt?: Date;
  updatedAt: Date;
  formId: Guid;
  form: Form;
  throttleFrequency?: CompetitionThrottleFrequencies;
  throttleEntryLimit?: number;
  throttleInterval?: number;
  throttleStartAt?: Date;
  throttleTimeZone?: string;
  properties: CompetitionProperties;

  get formHasReceiptValidator() {
    return !!this.form?.receiptValidatorDefinitionId;
  }

  constructor(props?: Partial<Competition>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.couponOfferId = Guid.valueOrUndefined(props.couponOfferId);
    this.name = asString(props.name, '');
    this.formId = Guid.valueOrNew(props.formId);
    this.form = new Form(props.form);
    this.form.name = 'data_capture_form';
    this.updatedAt = utcDateOrNow(props.updatedAt);
    this.openedAt = utcDateOrUndefined(props.openedAt);
    this.closedAt = utcDateOrUndefined(props.closedAt);
    this.properties = new CompetitionProperties(props.properties);

    if (
      this.entryStyle === CompetitionEntryStyles.One ||
      this.entryStyle === CompetitionEntryStyles.Many
    ) {
      this.throttleInterval = undefined;
      this.throttleFrequency = undefined;
    } else {
      this.throttleFrequency =
        this.throttleFrequency ?? CompetitionThrottleFrequencies.Week;
      this.throttleInterval = this.throttleInterval ?? 1;
      this.throttleEntryLimit = this.throttleEntryLimit ?? 1;
    }

    if (this.entryStyle === CompetitionEntryStyles.ThrottledTime) {
      this.throttleStartAt = utcDateOrUndefined(props.throttleStartAt);
    } else {
      this.throttleStartAt = undefined;
      this.throttleTimeZone = undefined;
    }
  }

  isClosed() {
    if (this.closedAt) {
      return isAfter(new Date(), this.closedAt);
    }
  }

  /**
   * Whether the competition style supports unique entry validation
   */
  supportsUniquenessValidation() {
    return entryStylesWithUniqueness.find((x) => x === this.entryStyle);
  }

  getStylingVariables(
    properties: SitePageProperties,
    card: SiteCard,
    ctaTextOverride?: string
  ): CompetitionStylingVariables {
    const DefaultEnterCompetitionButtonText = 'Enter competition';
    const getValueOrDefault = (value: string, defaultValue: string) => {
      if ((value || '').length === 0) {
        return defaultValue;
      }
      return value.trim();
    };

    return {
      buttonBackgroundColor: getPrimaryBackgroundColor({
        overrideColor: this.form.properties?.ButtonBackgroundColor,
        properties
      }),
      buttonTextColor: getPrimaryTextColor({
        overrideColor: this.form.properties?.ButtonTextColor,
        properties
      }),
      buttonText: this.form.properties?.ButtonText || 'Submit',
      successBackgroundColor: getPrimaryBackgroundColor({
        card,
        properties
      }),
      successTextColor: getPrimaryTextColor({
        card,
        properties
      }),
      closedMessage: this.form.properties?.ClosedMessage,
      callToActionButtonText: getValueOrDefault(
        ctaTextOverride || this.form.properties?.EntryButtonText,
        DefaultEnterCompetitionButtonText
      )
    };
  }

  static valueOrUndefined(value: Competition) {
    return !value ? undefined : new Competition(value);
  }

  get isRegistration() {
    return this.entryStyle === CompetitionEntryStyles.Registration;
  }
}

const entryStylesWithUniqueness = [
  CompetitionEntryStyles.One,
  CompetitionEntryStyles.Registration,
  CompetitionEntryStyles.ThrottledPublishTime,
  CompetitionEntryStyles.ThrottledRolling,
  CompetitionEntryStyles.ThrottledTime
];

export interface CompetitionStylingVariables {
  buttonBackgroundColor: string;
  buttonTextColor: string;
  buttonText: string;
  successBackgroundColor: string;
  successTextColor: string;
  closedMessage: string;
  callToActionButtonText: string;
}
