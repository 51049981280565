import { immerable } from 'immer';

import { CardTypes } from '@/common/models/CardTypes';
import {
  Dictionary,
  mapDictionary,
  reduceDictionary
} from '@komo-tech/core/models/Dictionary';
import { Guid } from '@komo-tech/core/models/Guid';
import { SiteCard } from '@/common/models/SiteCard';
import { SiteCardHelper } from '@/common/models/SiteCardHelper';

import { TreasureHuntCheckpointStatuses } from '../shared/TreasureHuntCheckpointStatuses';
import { TreasureHuntProperties } from '../shared/TreasureHuntProperties';
import { TreasureHuntStatuses } from '../shared/TreasureHuntStatuses';
import { TreasureHuntVariants } from '../shared/TreasureHuntVariants';
import { AdminTreasureHuntCheckpoint } from './AdminTreasureHuntCheckpoint';

export class AdminTreasureHunt implements SiteCard {
  [immerable] = true;

  id: Guid;
  siteId: Guid;
  readonly type: CardTypes = CardTypes.TreasureHunt;
  status: TreasureHuntStatuses;
  variant: TreasureHuntVariants;
  checkpoints: Dictionary<AdminTreasureHuntCheckpoint>;
  properties: TreasureHuntProperties;

  constructor(props?: Partial<AdminTreasureHunt>) {
    props = props || {};
    Object.assign(this, props);
    SiteCardHelper.applyDefaults(this, props);
    this.status = props.status || TreasureHuntStatuses.Closed;
    this.checkpoints = mapDictionary(
      props.checkpoints,
      (x) => new AdminTreasureHuntCheckpoint(x)
    );

    this.properties = new TreasureHuntProperties(props?.properties);
  }

  get simulatedCheckpointsCompletedCount(): number {
    return reduceDictionary(
      this.checkpoints,
      (count, checkpoint) => {
        if (
          checkpoint.properties.SimulatedStatus ===
          TreasureHuntCheckpointStatuses.Completed
        ) {
          return count + 1;
        }

        return count;
      },
      0
    );
  }

  // We don't have a gameplay to track score in admin, instead calculate it from the checkpoints
  get simulatedCheckpointsCompletedPoints(): number {
    return reduceDictionary(
      this.checkpoints,
      (points, checkpoint) => {
        if (
          checkpoint.properties.SimulatedStatus ===
          TreasureHuntCheckpointStatuses.Completed
        ) {
          return points + checkpoint.points;
        }

        return points;
      },
      0
    );
  }

  isCheckpointLocked(checkpoint: AdminTreasureHuntCheckpoint): boolean {
    if (
      !checkpoint ||
      checkpoint.properties.SimulatedStatus ===
        TreasureHuntCheckpointStatuses.Completed
    ) {
      return false;
    }

    if (this.status === TreasureHuntStatuses.Closed) {
      return true;
    }

    if (this.variant === TreasureHuntVariants.Unordered) {
      return false;
    }

    const orderIndex = this.properties.CheckpointOrderArray.findIndex(
      (g) => g.toString() === checkpoint.id.toString()
    );
    if (orderIndex <= 0) {
      return false;
    }

    const previousCheckpoint =
      this.checkpoints[
        this.properties.CheckpointOrderArray[orderIndex - 1].toString()
      ];

    if (!previousCheckpoint) {
      return false;
    }

    // locked if the previous checkpoint is not completed
    return (
      previousCheckpoint.properties.SimulatedStatus !==
      TreasureHuntCheckpointStatuses.Completed
    );
  }

  get hasRules(): boolean {
    return !!this.properties.RulesContent;
  }
}
