import { Guid } from '@komo-tech/core/models/Guid';
import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';
import { asNumber } from '@komo-tech/core/utils/number';

export class AdminMemoryTile {
  imageData: string;

  get Image() {
    return ImageDataModel.fromJsonOrUrl(this.imageData);
  }

  id: Guid;
  memoryChallengeId: Guid;
  pairNumber: number;

  constructor(props?: Partial<AdminMemoryTile>) {
    props = props || {};
    Object.assign(this, props);
    this.pairNumber = asNumber(props.pairNumber);
    this.memoryChallengeId = Guid.valueOrEmpty(props.memoryChallengeId);
    this.id = Guid.valueOrEmpty(props.id);
  }

  tileImageJsonOrCardImageJson(backOfAllTilesImage?: ImageDataModel) {
    return (
      this.Image ||
      backOfAllTilesImage ||
      ImageDataModel.fromJsonOrUrl(
        '/assets/images/back-add-image-placeholder.png'
      )
    );
  }
}
