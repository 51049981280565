import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import { useActionHandler } from '@/common/hooks/useActionHandler';
import { useFlag } from '@komo-tech/core/hooks/useFlag';
import { useOnMount } from '@komo-tech/core/hooks/useOnMount';
import { FrontAnyCard } from '@/common/models/AnyCard';
import { CardDescriptor } from '@/common/models/CardDescriptor';
import type { CardTypes } from '@/common/models/CardTypes';
import { Guid } from '@komo-tech/core/models/Guid';

import { PublicCardActions as Actions } from '../_actions';
import { PublicCardsCacheKeys as CacheKeys } from '../_cacheKeys';
import { useFrontCardsCacheSync } from './_useSync';

export const useFrontCardViewModel = (cardId: Guid, cardType: CardTypes) => {
  const queryClient = useQueryClient();
  const [cardDescriptor, setCardDescriptor] = useState<CardDescriptor>();
  const [typedCard, setTypedCard] = useState<FrontAnyCard>();
  const hasError = useFlag(false);

  const [handler, { isHandling }] = useActionHandler();

  // This viewModel query fetches both the card descriptor and the typed card at the same time,
  // and is used to initialize the local state and the ReactQuery cache.
  useOnMount(async () => {
    await handler(() => Actions.getViewModelAsync(cardId), {
      source: 'GET_CARD_VIEW_MODEL',
      onSuccess: (viewModel) => {
        setCardDescriptor(viewModel.cardDescriptor);
        queryClient.setQueryData(
          CacheKeys.descriptor(cardId),
          viewModel.cardDescriptor
        );
        setTypedCard(viewModel.typedFrontCard);
        queryClient.setQueryData(
          CacheKeys.typed(cardId),
          viewModel.typedFrontCard
        );
      },
      onError: hasError.setTrue,
      throwOnError: false,
      noToastOnError: true
    });
  });

  // This query ensures that ReactQuery doesn't evict the cached card descriptor.
  // It should not be used for the initial fetching of the card descriptor.
  // It will be used for re-fetching if the descriptor cache key is invalidated.
  useQuery(
    CacheKeys.descriptor(cardId),
    () => Actions.getDescriptorAsync(cardId),
    {
      staleTime: 5_000, // set staleTime to ensure it doesn't refetch on initial mount
      enabled: !!cardDescriptor, // let the viewModel call fetch the descriptor initially
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      cacheTime: Infinity
    }
  );

  // This query ensures that ReactQuery doesn't evict the cached typed card.
  // It should not be used for the initial fetching of the typed card.
  // It will be used for re-fetching if the typed card cache key is invalidated.
  useQuery(
    CacheKeys.typed(cardId),
    () => Actions.getTypedAsync(cardId, cardType),
    {
      staleTime: 5_000, // set staleTime to ensure it doesn't refetch on initial mount
      enabled: !!cardDescriptor && !!typedCard, // let the viewModel call fetch the typed card initially
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      cacheTime: Infinity
    }
  );

  useFrontCardsCacheSync({
    cardId,
    onDescriptorChange: setCardDescriptor,
    onTypedChange: setTypedCard
  });

  return {
    cardDescriptor,
    typedCard,
    isLoading: isHandling,
    hasError: hasError.value
  };
};
