import { Guid } from '@komo-tech/core/models/Guid';
import { IHasId } from '@komo-tech/core/models/IHasId';
import { IHasOrder, orderByDescending } from '@komo-tech/core/models/IHasOrder';
import { ScheduleTime } from '@komo-tech/core/models/ScheduleTime';
import { mapArray } from '@komo-tech/core/utils/array';
import { utcDateOrNow, utcDateOrUndefined } from '@komo-tech/core/utils/date';
import { immerable } from 'immer';

import { ICardIdentifier } from '@/common/models//CardIdentifier';
import { CardStatuses } from '@/common/models//CardStatuses';
import { CardSubTypes } from '@/common/models//CardSubTypes';
import { CardTiers } from '@/common/models//CardTiers';
import { CardTypes } from '@/common/models//CardTypes';
import { Competition } from '@/common/models//competitions/Competition';
import {
  FormField,
  FormFieldAggregateCollection
} from '@/common/models//form/FormField';
import { SitePageProperties } from '@/common/models//pages/shared/SitePageProperties';
import { ISiteResolveButton } from '@/common/models//site/ISiteResolveButton';
import { Tag } from '@/common/models//Tag';
import { AdminCardTemplateModel } from '@/common/models/CardTemplate';
import {
  CountdownLifeCycleEvents,
  TriviaGameLifeCycleEvents
} from '@/common/models/LifeCycleEvents';
import { PublishingSchedule } from '@/common/models/PublishingSchedule';
import {
  getPrimaryBackgroundColor,
  getPrimaryTextColor
} from '@/common/utils/ButtonFunctions';

import { CardResult } from './card-result/CardResult';
import {
  CardDescriptorProperties,
  GetCardDescriptorButtonOptions
} from './CardDescriptorProperties';

export class CardDescriptor
  implements IHasId<Guid>, IHasOrder, ICardIdentifier
{
  [immerable] = true;
  id: Guid;
  siteId: Guid;
  siteName: string;
  type: CardTypes;
  subType?: CardSubTypes;
  name: string = '';
  order: number = 1;
  competition?: Competition;
  extraPrizeFormFields: FormField[];
  competitionId?: Guid;
  isPinned: boolean = false;

  showAt?: Date;

  publishAt: ScheduleTime;
  unPublishAt: ScheduleTime;

  updatedAt: Date;
  createdAt: Date;
  properties: CardDescriptorProperties;
  status: CardStatuses;
  tier: CardTiers;

  tags: Tag[];
  results: CardResult[];

  getTemplateJsonData() {
    if (!this.properties.TemplateDataJson) return new AdminCardTemplateModel();
    try {
      return new AdminCardTemplateModel(
        JSON.parse(this.properties.TemplateDataJson)
      );
    } catch {
      return new AdminCardTemplateModel();
    }
  }

  getMediaGallery() {
    if (!this.properties.TemplateDataJson) return [];
    try {
      const templateModel = this.getTemplateJsonData();
      return templateModel.assets ?? [];
    } catch {
      return [];
    }
  }

  get isArchived() {
    return this.status === CardStatuses.Archived;
  }

  get isPublished() {
    return this.status === CardStatuses.Published;
  }

  get isDraft() {
    return this.status === CardStatuses.Draft;
  }

  get publishActionText() {
    const publishAction =
      this.status === CardStatuses.Published ? 'Un-publish' : 'Publish';
    const hasSchedule = this.hasPublishOrUnpublishDate;
    const publishActionText = hasSchedule
      ? `${publishAction} now`
      : publishAction;

    return publishActionText;
  }

  get hasPublishOrUnpublishDate() {
    return !!this.publishAt.utc || !!this.unPublishAt.utc;
  }

  get hasCompetition() {
    return !!this.competition;
  }

  get hasReceiptValidatorCompetition() {
    return !!this.competition?.formHasReceiptValidator;
  }

  get CoverTitle() {
    return this.properties.CoverEnabled ? this.properties.CoverTitle : '';
  }

  get CoverImage() {
    return this.properties.CoverImage;
  }

  getCoverProps(properties: SitePageProperties, competitionEntered?: boolean) {
    const competitionClosed =
      this.hasCompetition && this.competition.isClosed();

    const buttonText = this.properties.CoverButtonText;

    return {
      title: this.properties.CoverTitle,
      image: this.CoverImage,
      buttonEnabled: this.properties.CoverButtonEnabled,
      buttonText:
        this.hasCompetition && !competitionClosed && !competitionEntered
          ? this.competition.form.properties.EntryButtonText || buttonText
          : buttonText,
      titleEnabled: this.properties.CoverTitleEnabled,
      buttonBackgroundColor: getPrimaryBackgroundColor({
        overrideColor: this.properties.CoverButtonBackgroundColor,
        properties
      }),
      buttonTextColor: getPrimaryTextColor({
        overrideColor: this.properties.CoverButtonColor,
        properties
      }),
      hasCompetition: this.hasCompetition,
      competitionClosed,
      competitionMessage: competitionClosed
        ? this.competition?.form?.properties?.ClosedMessage
        : competitionEntered
          ? this.competition?.form?.properties?.SubmittedMessage
          : undefined,
      showCompetitionMessage: competitionClosed
        ? this.competition?.form?.properties?.ClosedMessageEnabled
        : competitionEntered
          ? this.competition?.form?.properties?.SubmittedMessageEnabled
          : undefined
    };
  }

  get sharingEnabled() {
    return this.properties.SharingEnabled;
  }

  constructor(props?: Partial<CardDescriptor>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrEmpty(props.id);
    this.siteId = Guid.valueOrEmpty(props.siteId);
    this.competitionId = Guid.valueOrUndefined(props.competitionId);
    this.competition = Competition.valueOrUndefined(props.competition);
    this.extraPrizeFormFields = FormField.toOrderedArray(
      props.extraPrizeFormFields
    );
    this.publishAt = new ScheduleTime(props.publishAt);
    this.showAt = utcDateOrUndefined(props.showAt);
    this.unPublishAt = new ScheduleTime(props.unPublishAt);
    this.updatedAt = utcDateOrNow(props.updatedAt);
    this.createdAt = utcDateOrUndefined(props.createdAt);
    this.properties = new CardDescriptorProperties(props.properties);
    this.tags = mapArray(props.tags, (x) => new Tag(x));
    this.results = mapArray(props.results, (x) => new CardResult(x));
  }

  get idAsString() {
    return this.id.toString();
  }

  getAggregatedFormFields() {
    const model = new FormFieldAggregateCollection();

    (this.competition?.form?.fields || []).forEach((field) => {
      model.addField(field);
    });

    this.extraPrizeFormFields.forEach((field) => {
      model.addField(field);
    });

    return model;
  }

  public isExpired(): boolean {
    return (
      this.status === CardStatuses.Published &&
      this.unPublishAt.utc?.getTime() <= new Date().getTime()
    );
  }

  get publishingSchedule(): PublishingSchedule {
    return new PublishingSchedule({
      publishAt: this.publishAt,
      unPublishAt: this.unPublishAt
    });
  }

  public getTimeZoneId(): string | undefined {
    return this.publishAt.timeZoneId ?? this.unPublishAt.timeZoneId;
  }

  getResult(id: Guid) {
    return this.results.find((x) => x.id.equals(id));
  }

  getLifeCycleEvents() {
    switch (this.type) {
      case CardTypes.Countdown:
        return CountdownLifeCycleEvents;
      case CardTypes.Trivia:
        return TriviaGameLifeCycleEvents;
      default:
        return [];
    }
  }

  getEnterCompetitionButton(
    site: ISiteResolveButton,
    properties: SitePageProperties,
    options?: GetCardDescriptorButtonOptions
  ) {
    return this.properties.getEnterCompetitionButton(site, properties, options);
  }

  getResultDownloadButton(
    site: ISiteResolveButton,
    properties: SitePageProperties,
    options?: GetCardDescriptorButtonOptions
  ) {
    return this.properties.getResultDownloadButton(site, properties, options);
  }

  getResultShareButton(
    site: ISiteResolveButton,
    properties: SitePageProperties,
    options?: GetCardDescriptorButtonOptions
  ) {
    return this.properties.getResultShareButton(site, properties, options);
  }

  getBackToHubButton(
    site: ISiteResolveButton,
    properties: SitePageProperties,
    options?: GetCardDescriptorButtonOptions
  ) {
    return this.properties.getBackToHubButton(site, properties, options);
  }

  getPlayAgainButton(
    site: ISiteResolveButton,
    properties: SitePageProperties,
    options?: GetCardDescriptorButtonOptions
  ) {
    return this.properties.getPlayAgainButton(site, properties, options);
  }

  hasNativeResultSharingFeature() {
    switch (this.type) {
      case CardTypes.Picker:
      case CardTypes.Swiper:
      case CardTypes.Quiz:
      case CardTypes.Canvas:
      case CardTypes.MemoryChallenge:
        return true;
      default:
        return false;
    }
  }

  hasResultDownloadFeature() {
    switch (this.type) {
      case CardTypes.Picker:
      case CardTypes.Canvas:
        //Canvas does as well but needs to reuse the code for picker
        return true;
      default:
        return false;
    }
  }

  canShowHeader() {
    switch (this.subType) {
      case CardSubTypes.Amondo:
      case CardSubTypes.ClickBanner:
        return false;
      default:
        return true;
    }
  }

  canShowFooterShare() {
    let enabledByType = true;

    switch (this.subType) {
      case CardSubTypes.Amondo:
      case CardSubTypes.ClickBanner:
        return (enabledByType = false);
    }

    return enabledByType && this.properties.SharingEnabled;
  }

  getEmbedMetaUrl(siteUrl: string): string {
    return `${siteUrl}/api/v1/live/cards/${this.id}/embed-meta`;
  }

  hasGameplay() {
    switch (this.type) {
      case CardTypes.Trivia:
      case CardTypes.Canvas:
      case CardTypes.Checklist:
      case CardTypes.InstantWin:
      case CardTypes.LiveSurvey:
      case CardTypes.MemoryChallenge:
      case CardTypes.NumberGuess:
      case CardTypes.Picker:
      case CardTypes.Predictive:
      case CardTypes.Quiz:
      case CardTypes.ScratchAndWin:
      case CardTypes.SpinToWin:
      case CardTypes.Upload:
      case CardTypes.TreasureHunt:
      case CardTypes.Swiper:
        return true;

      case CardTypes.Carousel:
        return this.subType === CardSubTypes.Vote;

      case CardTypes.Image:
      case CardTypes.Countdown:
      case CardTypes.Embed:
      case CardTypes.Fact:
      case CardTypes.Leaderboard:
      case CardTypes.Video:
        return false;

      default:
        const unsupported: never = this.type;
        console.warn('Should have handler for' + unsupported);
        return false;
    }
  }

  hasGameplayQuestions() {
    switch (this.type) {
      case CardTypes.Trivia:
      case CardTypes.Quiz:
      case CardTypes.Swiper:
      case CardTypes.Predictive:
        return true;

      case CardTypes.Canvas:
      case CardTypes.Checklist:
      case CardTypes.InstantWin:
      case CardTypes.LiveSurvey:
      case CardTypes.MemoryChallenge:
      case CardTypes.NumberGuess:
      case CardTypes.Picker:
      case CardTypes.ScratchAndWin:
      case CardTypes.SpinToWin:
      case CardTypes.Upload:
      case CardTypes.TreasureHunt:
      case CardTypes.Image:
      case CardTypes.Carousel:
      case CardTypes.Countdown:
      case CardTypes.Embed:
      case CardTypes.Fact:
      case CardTypes.Leaderboard:
      case CardTypes.Video:
        return false;

      default:
        const unsupported: never = this.type;
        console.warn('Should have handler for' + unsupported);
        return false;
    }
  }

  hasGameplayScore() {
    switch (this.type) {
      case CardTypes.Trivia:
      case CardTypes.MemoryChallenge:
      case CardTypes.Predictive:
      case CardTypes.TreasureHunt:
        return true;

      case CardTypes.Checklist:
      case CardTypes.Swiper:
      case CardTypes.Picker:
      case CardTypes.NumberGuess:
      case CardTypes.LiveSurvey:
      case CardTypes.InstantWin:
      case CardTypes.Quiz:
      case CardTypes.ScratchAndWin:
      case CardTypes.SpinToWin:
      case CardTypes.Upload:
      case CardTypes.Carousel:
      case CardTypes.Canvas:
      case CardTypes.Image:
      case CardTypes.Countdown:
      case CardTypes.Embed:
      case CardTypes.Fact:
      case CardTypes.Leaderboard:
      case CardTypes.Video:
        return false;

      default:
        const unsupported: never = this.type;
        console.warn('Should have handler for' + unsupported);
        return false;
    }
  }

  hasGameplayEnd() {
    switch (this.type) {
      case CardTypes.Quiz:
      case CardTypes.Trivia:
      case CardTypes.MemoryChallenge:
      case CardTypes.TreasureHunt:
      case CardTypes.Canvas:
      case CardTypes.Checklist:
      case CardTypes.Swiper:
      case CardTypes.Picker:
      case CardTypes.NumberGuess:
      case CardTypes.InstantWin:
      case CardTypes.ScratchAndWin:
      case CardTypes.SpinToWin:
      case CardTypes.Upload:
        return true;

      case CardTypes.Carousel:
        return this.subType === CardSubTypes.Vote;

      case CardTypes.Predictive:
      case CardTypes.LiveSurvey:
      case CardTypes.Countdown:
      case CardTypes.Embed:
      case CardTypes.Fact:
      case CardTypes.Image:
      case CardTypes.Leaderboard:
      case CardTypes.Video:
        return false;

      default:
        const unsupported: never = this.type;
        console.warn('Should have handler for' + unsupported);
        return false;
    }
  }

  // This is used to determine if the user should have to verify their email
  // to access an existing game play.
  // This includes Trivia, Predictor, Q+A, Treasure Hunt and Memory Challenge
  get shouldVerifyReturningUser() {
    return (
      this.competition.isRegistration || this.type === CardTypes.MemoryChallenge
    );
  }

  /**
   * Sort the card descriptors the same way they are viewed in the editor.
   * Pinned cards first, in descending "order". Then unpinned cards in descending order.
   * @param items
   */
  static sortArray(items: CardDescriptor[]) {
    const pinned = items.filter((i) => i.isPinned).sort(orderByDescending);
    const notPinned = items.filter((i) => !i.isPinned).sort(orderByDescending);
    return [...pinned, ...notPinned];
  }
}
