import { immerable } from 'immer';

import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';
import { SiteCardProperties } from '@/common/models/SiteCardProperties';
import { asBoolean } from '@komo-tech/core/utils/boolean';
import { asNumber } from '@komo-tech/core/utils/number';

const white = '#ffffff';

export class LiveSurveyProperties implements SiteCardProperties {
  [immerable] = true;

  ButtonTextColor?: string;
  ButtonBackgroundColor?: string;

  //Header
  HeaderLogoDisabled: boolean = false;
  HeaderLogoJson?: string;
  get HeaderLogo(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.HeaderLogoJson);
  }
  HeaderCoverImageJson?: string;
  get HeaderCoverImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.HeaderCoverImageJson);
  }
  HeaderBgColor: string;
  HeaderColor: string;
  HeaderTitle?: string;
  HeaderSubtitle?: string;

  HeaderTabActiveColor?: string;
  HeaderTabInactiveColor?: string;
  HeaderQuestionsCountColor?: string;

  //Body
  BodyBgColor: string;
  BodyBgImageJson?: string;
  get BodyBgImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.BodyBgImageJson);
  }

  BodyQuestionBgColor: string;
  BodyQuestionHeadingColor: string;
  BodyQuestionTextColor: string;

  //Footer
  FooterBgColor?: string;
  FooterColor?: string;

  //Questions
  QuestionInputPlaceholder: string;
  ReactionFillColor: string;
  MaxParticipantQuestions: number;
  QuestionMaxCharacterLength: number;
  QuestionSubmissionsEnabled: boolean;

  static defaults: Partial<LiveSurveyProperties> = {
    //Questions
    MaxParticipantQuestions: 3,
    QuestionMaxCharacterLength: 180,
    QuestionInputPlaceholder: 'Ask a question...',

    //Header
    HeaderLogoDisabled: false,
    HeaderBgColor: white,
    HeaderColor: '#212529',

    //Body
    BodyBgColor: '	#e9ecef',
    BodyQuestionBgColor: white,
    BodyQuestionHeadingColor: '	#495057',
    BodyQuestionTextColor: '#212529',
    ReactionFillColor: '#228be6'
  };

  constructor(props?: Partial<LiveSurveyProperties>) {
    Object.assign(this, {
      ...LiveSurveyProperties.defaults,
      ...props
    });
    this.HeaderLogoDisabled = asBoolean(this.HeaderLogoDisabled);
    this.MaxParticipantQuestions = asNumber(this.MaxParticipantQuestions);
    this.QuestionMaxCharacterLength = asNumber(this.QuestionMaxCharacterLength);
    this.QuestionSubmissionsEnabled = asBoolean(
      this.QuestionSubmissionsEnabled
    );
  }
}
