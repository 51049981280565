import { ColorRGBA } from '@komo-tech/core/models/ColorRGBA';
import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';
import { asBoolean } from '@komo-tech/core/utils/boolean';
import { asNumber } from '@komo-tech/core/utils/number';

import { PredictiveQuestionStatusColors } from '@/common/models/predictive/types';
import { SiteCardProperties } from '@/common/models/SiteCardProperties';

import { PredictiveStyle } from './PredictiveStyle';

export class PredictiveProperties implements SiteCardProperties {
  Style: PredictiveStyle;
  //SiteCardProperties
  ButtonTextColor?: string;
  ButtonBackgroundColor?: string;
  //end
  ButtonText?: string = PredictiveProperties.defaultButtonText;

  GameCoverImageUrl?: string;

  get GameCoverImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.GameCoverImageUrl);
  }

  LogoImageUrl?: string;

  get LogoImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.LogoImageUrl);
  }

  HeaderBackgroundColor?: ColorRGBA;
  ScoreDisplayBackgroundColor?: ColorRGBA;
  ScoreDisplayTextColor?: string = '#000000';
  BackgroundColor?: string = '#F2F3F4';
  BackgroundUrl?: string;

  get BackgroundImage() {
    return ImageDataModel.fromJsonOrUrl(this.BackgroundUrl);
  }

  BackgroundImageEnabled?: boolean = false;
  BackgroundFilter?: ColorRGBA;
  BackgroundFilterEnabled?: boolean = false;

  FooterBannerImageEnabled?: boolean = false;
  FooterBannerImageJson?: string;
  get FooterBannerImage() {
    return ImageDataModel.fromJsonOrUrl(this.FooterBannerImageJson);
  }

  /**
   * The foreground (text) color
   */
  Color?: string = '#000000';
  HeaderColor?: string = '#000000';
  ActiveTabColor?: string = '#000000';
  InactiveTabColor?: string = '	#868e96';

  QuestionsTabLabel?: string = 'Questions';
  LeaderboardTabLabel?: string = 'Leaderboard';
  PrizesTabLabel?: string = 'Prizes';

  /**
   * The prize colors
   */
  PrizeTextColor: ColorRGBA = new ColorRGBA('#000000');
  PrizeCardColor: ColorRGBA = new ColorRGBA();
  PrizeWonBannerText: string = '🎉 Congrats! You won this prize!';
  PrizeWonBannerTextColor: ColorRGBA = new ColorRGBA('#010D20');
  PrizeWonBannerBackgroundColor: ColorRGBA;
  PrizeEnteredBannerText: string =
    "🤞 You're in with a chance to win this prize!";
  PrizeEnteredBannerTextColor: ColorRGBA = new ColorRGBA('#010D20');
  PrizeEnteredBannerBackgroundColor: ColorRGBA;
  PrizeLostBannerText: string = "Sorry! You didn't win this time";
  PrizeLostBannerTextColor: ColorRGBA = new ColorRGBA('#010D20');
  PrizeLostBannerBackgroundColor: ColorRGBA;

  //Leaderboard
  ShowFullName?: boolean;
  LeaderboardTileColor: ColorRGBA;
  LeaderboardTextColor: ColorRGBA = new ColorRGBA('#000000');
  LeaderboardEntries: number = 10;
  EnableLeaderboardPerSegment: boolean;

  // Close game
  IsGameClosed?: boolean;
  GameClosedCtaText?: string = 'View results';

  // question colors
  QuestionPendingTextColor: ColorRGBA;
  QuestionPendingBackgroundColor: ColorRGBA;
  QuestionCorrectTextColor: ColorRGBA;
  QuestionCorrectBackgroundColor: ColorRGBA;
  QuestionIncorrectTextColor: ColorRGBA;
  QuestionIncorrectBackgroundColor: ColorRGBA;
  QuestionInvalidTextColor: ColorRGBA;
  QuestionInvalidBackgroundColor: ColorRGBA;

  HideResponsePercentages: boolean = false;

  constructor(props?: Partial<PredictiveProperties>) {
    props = props || {};
    Object.assign(this, props);
    this.BackgroundImageEnabled = asBoolean(
      props.BackgroundImageEnabled,
      false
    );
    this.BackgroundFilterEnabled = asBoolean(
      props.BackgroundFilterEnabled,
      false
    );

    this.FooterBannerImageEnabled = asBoolean(
      props.FooterBannerImageEnabled,
      false
    );

    this.HeaderBackgroundColor = ColorRGBA.valueOrDefault(
      props.HeaderBackgroundColor,
      PredictiveProperties.defaultHeaderBackgroundColor
    );

    this.PrizeWonBannerBackgroundColor = ColorRGBA.valueOrDefault(
      props.PrizeWonBannerBackgroundColor,
      PredictiveProperties.defaultPrizeWonBackgroundColor
    );
    this.PrizeEnteredBannerBackgroundColor = ColorRGBA.valueOrDefault(
      props.PrizeEnteredBannerBackgroundColor,
      PredictiveProperties.defaultPrizeEnteredBackgroundColor
    );
    this.PrizeLostBannerBackgroundColor = ColorRGBA.valueOrDefault(
      props.PrizeLostBannerBackgroundColor,
      PredictiveProperties.defaultPrizeLostBackgroundColor
    );
    this.ScoreDisplayBackgroundColor = ColorRGBA.valueOrDefault(
      props.ScoreDisplayBackgroundColor,
      PredictiveProperties.defaultScoreDisplayBackgroundColor
    );
    this.PrizeTextColor = ColorRGBA.valueOrDefault(
      props.PrizeTextColor,
      PredictiveProperties.defaultPrizeTextColor
    );
    this.PrizeCardColor = ColorRGBA.valueOrDefault(
      props.PrizeCardColor,
      PredictiveProperties.defaultPrizeCardColor
    );
    this.PrizeWonBannerTextColor = ColorRGBA.valueOrDefault(
      props.PrizeWonBannerTextColor,
      PredictiveProperties.defaultPrizeWonBannerTextColor
    );
    this.PrizeEnteredBannerTextColor = ColorRGBA.valueOrDefault(
      props.PrizeEnteredBannerTextColor,
      PredictiveProperties.defaultPrizeEnteredBannerTextColor
    );
    this.PrizeLostBannerTextColor = ColorRGBA.valueOrDefault(
      props.PrizeLostBannerTextColor,
      PredictiveProperties.defaultPrizeLostBannerTextColor
    );
    this.LeaderboardTextColor = ColorRGBA.valueOrDefault(
      props.LeaderboardTextColor,
      PredictiveProperties.defaultLeaderboardTextColor
    );

    this.BackgroundFilter = ColorRGBA.valueOrUndefined(props.BackgroundFilter);
    this.ShowFullName = asBoolean(props.ShowFullName, false);
    this.LeaderboardTileColor = new ColorRGBA(props.LeaderboardTileColor);
    this.LeaderboardEntries = asNumber(props.LeaderboardEntries, 10);
    this.EnableLeaderboardPerSegment = asBoolean(
      props.EnableLeaderboardPerSegment,
      false
    );

    this.IsGameClosed = asBoolean(props.IsGameClosed, false);

    this.QuestionPendingTextColor = ColorRGBA.valueOrDefault(
      props.QuestionPendingTextColor,
      PredictiveProperties.defaultQuestionPendingTextColor
    );
    this.QuestionPendingBackgroundColor = ColorRGBA.valueOrDefault(
      props.QuestionPendingBackgroundColor,
      PredictiveProperties.defaultQuestionPendingBackgroundColor
    );
    this.QuestionCorrectTextColor = ColorRGBA.valueOrDefault(
      props.QuestionCorrectTextColor,
      PredictiveProperties.defaultQuestionCorrectTextColor
    );
    this.QuestionCorrectBackgroundColor = ColorRGBA.valueOrDefault(
      props.QuestionCorrectBackgroundColor,
      PredictiveProperties.defaultQuestionCorrectBackgroundColor
    );
    this.QuestionIncorrectTextColor = ColorRGBA.valueOrDefault(
      props.QuestionIncorrectTextColor,
      PredictiveProperties.defaultQuestionIncorrectTextColor
    );
    this.QuestionIncorrectBackgroundColor = ColorRGBA.valueOrDefault(
      props.QuestionIncorrectBackgroundColor,
      PredictiveProperties.defaultQuestionIncorrectBackgroundColor
    );
    this.QuestionInvalidTextColor = ColorRGBA.valueOrDefault(
      props.QuestionInvalidTextColor,
      PredictiveProperties.defaultQuestionInvalidTextColor
    );
    this.QuestionInvalidBackgroundColor = ColorRGBA.valueOrDefault(
      props.QuestionInvalidBackgroundColor,
      PredictiveProperties.defaultQuestionInvalidBackgroundColor
    );

    this.HideResponsePercentages = asBoolean(
      props.HideResponsePercentages,
      false
    );
  }

  static defaultHeaderBackgroundColor = ColorRGBA.fromHex('#FFFFFF');

  static defaultPrizeWonBackgroundColor = ColorRGBA.fromHex('#B0F298');
  static defaultPrizeEnteredBackgroundColor = ColorRGBA.fromHex('#F9E995');
  static defaultPrizeLostBackgroundColor = ColorRGBA.fromHex('#E6E7E9');
  static defaultScoreDisplayBackgroundColor = ColorRGBA.fromRGBA(
    255,
    255,
    255,
    0.07
  );
  static defaultPrizeCardColor = ColorRGBA.fromHex('#ffffff');
  static defaultPrizeTextColor = ColorRGBA.fromHex('#000000');
  static defaultPrizeWonBannerTextColor = ColorRGBA.fromHex('#010D20');
  static defaultPrizeEnteredBannerTextColor = ColorRGBA.fromHex('#010D20');
  static defaultPrizeLostBannerTextColor = ColorRGBA.fromHex('#010D20');
  static defaultLeaderboardTextColor = ColorRGBA.fromHex('#000000');

  static defaultQuestionPendingTextColor = ColorRGBA.fromHex('#FFFFFF');
  static defaultQuestionPendingBackgroundColor = ColorRGBA.fromHex('#0755EB');
  static defaultQuestionCorrectTextColor = ColorRGBA.fromHex('#010D20');
  static defaultQuestionCorrectBackgroundColor = ColorRGBA.fromHex('#B0F298');
  static defaultQuestionIncorrectTextColor = ColorRGBA.fromHex('#FFFFFF');
  static defaultQuestionIncorrectBackgroundColor = ColorRGBA.fromHex('#B10303');
  static defaultQuestionInvalidTextColor = ColorRGBA.fromHex('#FFFFFF');
  static defaultQuestionInvalidBackgroundColor = ColorRGBA.fromHex('#4D5563');

  static defaultButtonText = 'PLAY NOW';

  getBackgroundFilterColorRgba() {
    if (!this.BackgroundFilterEnabled || !this.BackgroundFilter) {
      return undefined;
    }

    return this.BackgroundFilter;
  }

  /**
   * Get the background image URL if it has been enabled
   */
  getApplicableBackgroundImage(): ImageDataModel {
    if (!this.BackgroundImageEnabled) {
      return undefined;
    }

    return this.BackgroundImage;
  }

  getQuestionStatusColors(): PredictiveQuestionStatusColors {
    return {
      pending: {
        color: this.QuestionPendingTextColor,
        backgroundColor: this.QuestionPendingBackgroundColor
      },
      correct: {
        color: this.QuestionCorrectTextColor,
        backgroundColor: this.QuestionCorrectBackgroundColor
      },
      incorrect: {
        color: this.QuestionIncorrectTextColor,
        backgroundColor: this.QuestionIncorrectBackgroundColor
      },
      invalid: {
        color: this.QuestionInvalidTextColor,
        backgroundColor: this.QuestionInvalidBackgroundColor
      }
    };
  }

  static getDefaultQuestionStatusColors(): PredictiveQuestionStatusColors {
    return {
      pending: {
        color: PredictiveProperties.defaultQuestionPendingTextColor,
        backgroundColor:
          PredictiveProperties.defaultQuestionPendingBackgroundColor
      },
      correct: {
        color: PredictiveProperties.defaultQuestionCorrectTextColor,
        backgroundColor:
          PredictiveProperties.defaultQuestionCorrectBackgroundColor
      },
      incorrect: {
        color: PredictiveProperties.defaultQuestionIncorrectTextColor,
        backgroundColor:
          PredictiveProperties.defaultQuestionIncorrectBackgroundColor
      },
      invalid: {
        color: PredictiveProperties.defaultQuestionInvalidTextColor,
        backgroundColor:
          PredictiveProperties.defaultQuestionInvalidBackgroundColor
      }
    };
  }
}
