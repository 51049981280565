import { CSSProperties } from 'react';
import isEqual from 'react-fast-compare';

import { HeightWidth } from '../models/HeightWidth';
import {
  aspectRatioCss,
  getClampedSize,
  getHeightByRatio
} from '../utils/size';
import { useDeepCompareLayoutEffect } from './useDeepCompareEffects';
import { useLatest } from './useLatest';
import { useStateWithRef } from './useStateWithRef';
import { useWindowSize } from './useWindowSize';

interface Options {
  maxWidth: number;
  aspectRatio?: HeightWidth;
  maxHeightFunc?: (windowOuterHeight: number) => number;
}
export const useClampedSize = ({
  maxWidth,
  aspectRatio,
  maxHeightFunc
}: Options) => {
  const maxHeightFuncRef = useLatest(maxHeightFunc);
  const windowSize = useWindowSize();
  const [state, setState, stateRef] = useStateWithRef(
    new ClampedSizeStyleModel({
      aspectRatio,
      maxWidth,
      maxHeight: maxHeightFuncRef?.current?.(window.innerHeight)
    })
  );

  useDeepCompareLayoutEffect(() => {
    const newState = new ClampedSizeStyleModel({
      aspectRatio,
      maxWidth,
      maxHeight: maxHeightFuncRef?.current?.(window.innerHeight)
    });
    if (!isEqual(newState, stateRef.current)) {
      setState(newState);
    }
  }, [maxWidth, aspectRatio, windowSize]);

  return state;
};

interface ClampedSizeStyleModelOptions {
  aspectRatio: HeightWidth;
  maxWidth: number;
  maxHeight?: number;
  clampedSize?: HeightWidth;
}

export class ClampedSizeStyleModel {
  aspectRatio: HeightWidth;
  maxWidth: number;
  maxHeight?: number;

  get hasAspectRatio() {
    return !!this.aspectRatio?.height && !!this.aspectRatio?.width;
  }

  get isClampedByHeight() {
    if (!this.hasAspectRatio || !this.maxHeight) return false;
    return getHeightByRatio(this.aspectRatio, this.maxWidth) > this.maxHeight;
  }

  get size(): HeightWidth {
    if (!this.hasAspectRatio) {
      return {
        width: this.maxWidth,
        height: this.maxHeight
      };
    }
    const sizeByWidth: HeightWidth = {
      width: this.maxWidth,
      height: getHeightByRatio(this.aspectRatio, this.maxWidth)
    };

    return this.isClampedByHeight
      ? getClampedSize({
          size: sizeByWidth,
          maxHeight: this.maxHeight
        })
      : sizeByWidth;
  }

  get style(): ClampedStyle {
    return this._getStyle();
  }

  get styleWithForcedSize(): CSSProperties {
    if (!this.hasAspectRatio) {
      return {
        maxWidth: this.maxWidth,
        maxHeight: this.maxHeight,
        width: '100%'
      };
    }

    const style = this._getStyle();
    style.height = this.size.height;
    style.width = this.size.width;
    style.aspectRatio = 'unset';
    return style;
  }

  constructor(options: ClampedSizeStyleModelOptions) {
    this.aspectRatio = options.aspectRatio;
    this.maxWidth = options.maxWidth;
    this.maxHeight = options.maxHeight;
  }

  private _getStyle() {
    if (!this.hasAspectRatio) {
      return {
        maxWidth: this.maxWidth,
        maxHeight: this.maxHeight,
        width: '100%'
      };
    }

    if (!this.isClampedByHeight) {
      return {
        aspectRatio: aspectRatioCss(this.aspectRatio),
        width: '100%'
      };
    }

    return {
      aspectRatio: aspectRatioCss(this.aspectRatio),
      height: this.size.height,
      width: this.size.width,
      marginLeft: 'auto',
      marginRight: 'auto'
    };
  }
}

interface ClampedStyle {
  aspectRatio?: string;
  maxWidth?: string | number;
  maxHeight?: string | number;
  width: string | number;
  height?: string | number;
  marginLeft?: string | number;
  marginRight?: string | number;
}
