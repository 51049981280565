import { immerable } from 'immer';

import { Guid } from '@komo-tech/core/models/Guid';
import { FrontTreasureHuntGameplay } from '@/common/models/treasure-hunt/Front/FrontTreasureHuntGameplay';
import { TreasureHuntCheckpointFilterTypes } from '@/common/models/treasure-hunt/shared/TreasureHuntCheckpointFilterTypes';

import { TreasureHuntCheckpointProperties } from '../shared/TreasureHuntCheckpointProperties';
import { TreasureHuntCheckpointTypes } from '../shared/TreasureHuntCheckpointTypes';

export class FrontTreasureHuntCheckpoint {
  [immerable] = true;

  id: Guid;
  cardId: Guid;
  name: string;
  type: TreasureHuntCheckpointTypes;
  points: number;
  isOpen: boolean;
  properties: TreasureHuntCheckpointProperties;

  constructor(props?: Partial<FrontTreasureHuntCheckpoint>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.cardId = Guid.valueOrEmpty(props.cardId);
    this.properties = new TreasureHuntCheckpointProperties(props.properties);
  }

  passesFilter(
    filter: TreasureHuntCheckpointFilterTypes,
    gameplay: FrontTreasureHuntGameplay
  ): boolean {
    switch (filter) {
      case TreasureHuntCheckpointFilterTypes.Completed:
        return gameplay.isCheckpointCompleted(this.id);
      case TreasureHuntCheckpointFilterTypes.Remaining:
        return !gameplay.isCheckpointCompleted(this.id);
      default:
        return false;
    }
  }
}
