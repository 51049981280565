import { CardTypes } from '@/common/models/CardTypes';
import { FrontCarouselItem } from '@/common/models/carousel/Front/FrontCarouselItem';
import { Guid } from '@komo-tech/core/models/Guid';
import { SiteCard } from '@/common/models/SiteCard';
import { SiteCardHelper } from '@/common/models/SiteCardHelper';
import { mapArray, shuffleArray } from '@komo-tech/core/utils/array';

import { CarouselProperties } from '../shared';

export class FrontCarousel implements SiteCard {
  id: Guid;
  siteId: Guid;
  readonly type: CardTypes = CardTypes.Carousel;
  items: FrontCarouselItem[];
  properties: CarouselProperties;

  constructor(props?: Partial<FrontCarousel>) {
    props = props || {};
    Object.assign(this, props);
    SiteCardHelper.applyDefaults(this, props);
    this.items = mapArray(props.items, (x) => new FrontCarouselItem(x));
    this.properties = new CarouselProperties(props.properties);
  }

  getShuffleIds(ignoreShuffle: boolean) {
    const ids = mapArray(this.items, (x) => new FrontCarouselItem(x)).map(
      (x) => x.id
    );

    if (ignoreShuffle || this.properties.DontShuffleItems) {
      return ids;
    }

    return shuffleArray(ids);
  }

  getItemsForRender(orderedIds: Guid[]) {
    return mapArray(this.items, (x) => new FrontCarouselItem(x)).sort(
      Guid.orderByIdArray(orderedIds)
    );
  }
}
