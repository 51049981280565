import { nanoid } from '@komo-tech/core/utils/nanoid';

import { HeightWidth } from '@komo-tech/core/models/HeightWidth';
import isNil from 'lodash/isNil';

import { CanvasImageData } from './CanvasImageData';
import { CanvasItemTypes } from './CanvasItemType';
import { CanvasTextData } from './CanvasTextData';

export interface CloneCanvasItemOptions {
  positionOffset: number;
}
export class CanvasItem {
  id: string;
  stageSize: HeightWidth;
  type: CanvasItemTypes;
  x: number;
  y: number;
  rotation: number;
  width?: number;
  height?: number;
  imageData?: CanvasImageData;
  textData?: CanvasTextData;

  constructor(props?: Partial<CanvasItem>) {
    props = props || {};
    Object.assign(this, props);
    if (!props.id) this.id = nanoid();
    if (isNil(this.x)) this.x = 0;
    if (isNil(this.y)) this.y = 0;
    if (isNil(this.rotation)) this.rotation = 0;
    switch (this.type) {
      case CanvasItemTypes.Image:
        this.imageData = new CanvasImageData(props.imageData) as any;
        break;
      case CanvasItemTypes.Text:
        this.textData = new CanvasTextData(props.textData) as any;
        break;
    }
  }

  isImage() {
    return this.type === CanvasItemTypes.Image;
  }

  isInteractiveTextItem() {
    if (this.type !== CanvasItemTypes.Text || !this?.textData) {
      return false;
    }
    return this.textData.interactiveType === 'interactive';
  }

  clone(options?: CloneCanvasItemOptions) {
    const clone = new CanvasItem(this);
    clone.id = nanoid();
    if (clone.textData?.interactiveLabel) {
      clone.textData.interactiveLabel = undefined;
    }

    if (options?.positionOffset) {
      clone.x = clone.x + 2;
      clone.y = clone.y + 2;
    }
    return clone;
  }

  static new(
    type: CanvasItemTypes,
    stageSize: HeightWidth,
    initial: Partial<CanvasItem> = {}
  ): CanvasItem {
    const item = new CanvasItem({
      ...initial,
      type
    });

    if (!item.stageSize) {
      item.stageSize = { ...stageSize };
      if (isNil(item.x) || isNil(item.y)) {
        item.x = stageSize.width / 2;
        item.y = stageSize.height / 2;
      }
    }

    return item;
  }

  static newTextItem(stageSize: HeightWidth, text: string): CanvasItem {
    const item = CanvasItem.new(CanvasItemTypes.Text, stageSize);
    item.textData.text = text;
    return item;
  }

  static newImageItem(stageSize: HeightWidth, url: string): CanvasItem {
    const item = CanvasItem.new(CanvasItemTypes.Image, stageSize);
    item.imageData.url = url;
    return item;
  }
}
