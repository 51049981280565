import { ButtonDataModel } from '@/common/models/ButtonDataModel';
import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';
import { mapArray } from '@komo-tech/core/utils/array';
import { asBoolean } from '@komo-tech/core/utils/boolean';

export class FrontSpinToWinResult {
  title: string;
  description: string;
  image: ImageDataModel;
  hasForm: boolean;
  formCompleted: boolean;
  confettiEnabled: boolean;
  extraButtons: ButtonDataModel[];

  constructor(props?: Partial<FrontSpinToWinResult>) {
    props = props || {};
    Object.assign(this, props);
    this.image = ImageDataModel.fromJsonOrUrl(props.image);
    this.hasForm = asBoolean(props.hasForm);
    this.formCompleted = asBoolean(props.formCompleted);
    this.confettiEnabled = asBoolean(props.confettiEnabled);
    this.extraButtons = mapArray(
      props.extraButtons,
      (b) => new ButtonDataModel(b)
    );
  }

  setFormCompleted() {
    return new FrontSpinToWinResult({ ...this, formCompleted: true });
  }
}
