import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';
import { SiteCardProperties } from '@/common/models/SiteCardProperties';

export class UploadProperties implements SiteCardProperties {
  ButtonTextColor?: string;
  ButtonBackgroundColor?: string;
  CoverImageUrl: string;

  get CoverImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.CoverImageUrl);
  }

  constructor(props?: Partial<UploadProperties>) {
    props = props || {};
    Object.assign(this, props);
  }
}
